<template>
    <div class="app-container">
        <el-form :size="$store.getters.size" :inline="true" :model="queryParams" ref="queryParams">
            <el-form-item>
                <el-input placeholder="标题" v-model="queryParams.title" clearable></el-input>
            </el-form-item>
            <el-form-item>
                <el-input placeholder="操作人员" v-model="queryParams.operName" clearable></el-input>
            </el-form-item>
            <el-form-item>
                <el-select v-model="queryParams.method" placeholder="请求方式" clearable>
                    <el-option v-for="(item, index) in methodOptions" :label="item.label" :value="item.value" :key="index"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="handlerQuery" :loading="loading">查询</el-button>
            </el-form-item>
        </el-form>
        <el-row :gutter="15" class="mb20">
            <el-col :span="18">
                <el-button
                    type="danger"
                    plain
                    :disabled="multipleSelection.length<=0"
                    icon="el-icon-delete"
                    v-if="$app.haveRole('system:log:deletes')"
                    size="mini" @click="handleDeletes">批量删除</el-button>

                <el-button
                    type="danger"
                    icon="el-icon-delete"
                    v-if="$app.haveRole('system:log:clean')"
                    size="mini" @click="handleClean">清空日志</el-button>
            </el-col>
        </el-row>
        <el-table :data="tableData" v-loading="loading" @selection-change="handleSelectionChange"
                  row-key="id" :size="$store.getters.size" >
            <el-table-column type="expand">
                <template slot-scope="scope">
                    <el-form label-width="100px" :size="$store.getters.size" class="table-expand-form">
                        <el-form-item label="操作模块">{{scope.row.title}}</el-form-item>
                        <el-form-item label="请求方法">{{scope.row.method}}</el-form-item>
                        <el-form-item label="请求方式">{{scope.row.requestMethod}}</el-form-item>
                        <el-form-item label="请求地址">{{scope.row.operUrl}}</el-form-item>
                        <el-form-item label="操作人员">{{scope.row.operName}}</el-form-item>
                        <el-form-item label="IP地址">{{scope.row.operIp}}</el-form-item>
                        <el-form-item label="操作状态">{{scope.row.status===0 ? '正常':'异常'}}</el-form-item>
                        <el-form-item label="请求参数"><p style="word-break: break-all;">{{scope.row.operParam}}</p></el-form-item>
                        <el-form-item label="返回参数"><p style="word-break: break-all;">{{scope.row.jsonResult}}</p></el-form-item>
                        <el-form-item label="错误消息" v-if="scope.row.status!==0"><p style="word-break: break-all;">{{scope.row.errorMsg}}</p></el-form-item>
                    </el-form>
                </template>
            </el-table-column>
            <el-table-column type="selection" width="55" align="center"></el-table-column>
            <el-table-column label="操作模块" prop="title" width="150"></el-table-column>
            <el-table-column label="请求方式" prop="requestMethod" width="120" align="center"></el-table-column>
            <el-table-column label="请求方法" prop="method" width="300"></el-table-column>
            <el-table-column label="操作人员" prop="operName" width="220" align="center"></el-table-column>
            <el-table-column label="IP地址" prop="operIp" width="120" align="center"></el-table-column>
            <el-table-column label="操作状态" width="80" align="center">
                <template slot-scope="scope">
                    {{scope.row.status===0 ? '正常':'异常'}}
                </template>
            </el-table-column>
            <el-table-column label="请求地址" prop="operUrl" width="330"></el-table-column>
            <el-table-column label="操作时间" prop="operTime" width="220"></el-table-column>
        </el-table>

        <page-item :total="total" ref="pageItem"/>
    </div>
</template>

<script>
    import PageItem from '@/components/PageItem'
    import {getList, removes, cleanLog} from '@/api/system/log'

    export default {
        name: "SystemLog",
        components: {PageItem},
        data(){
            return{
                queryParams: {
                    title: undefined,
                    operName: undefined,
                    method: undefined
                },
                total: 0,
                loading: false,
                multipleSelection: [],
                tableData:[],
                methodOptions:[{label:'GET',value:'GET'},{label:'POST',value:'POST'},{label:'PUT',value:'PUT'},{label:'DELETE',value:'DELETE'}]
            }
        },
        mounted() {
            this.getTableData();
        },
        methods: {
            /** 获取表数据 */
            getTableData(){
                if(this.loading){return;}
                this.loading = true;
                this.queryParams.size = this.$refs['pageItem'].pageSize;
                this.queryParams.current = this.$refs['pageItem'].current;
                getList(this.queryParams).then(({code, data})=>{
                    this.loading = false
                    if(code === 0){
                        this.tableData = data.pageData;
                        this.total = data.total;
                    }
                })
            },
            /** 搜索 */
            handlerQuery(){
                this.$refs['pageItem'].current = 1;
                this.getTableData();
            },
            /** 批量删除 */
            handleDeletes(){
                this.$confirm('你确定要删除选中日志','提示',{
                    type: "warning"
                }).then(()=>{
                    if(this.multipleSelection.length<=0){
                        this.$message.error("未选择需要删除的日志")
                    } else {
                        let data = this.multipleSelection.map(item => {return item.id})
                        removes(data).then(({code})=>{
                            if(code === 0 ){
                                this.$message.success("批量删除成功");
                                this.getTableData();
                            }
                        })
                    }
                })
            },
            /** 清空日志 */
            handleClean(){
                this.$confirm('你确定要清空所有日志?','提示',{
                    type: "warning"
                }).then(()=>{
                    cleanLog().then(({code})=>{
                        if(code === 0){
                            this.$message.success("清空成功");
                            this.getTableData();
                        }
                    })
                }).catch(()=>{})
            },
            /** 多选 */
            handleSelectionChange(val){
                this.multipleSelection = val;
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
