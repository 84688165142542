import request from '@/utils/request'

// 分页获取数据
export function getList(data) {
    return request({
        url: '/system/log/list',
        method: 'GET',
        params: data
    })
}

// 批量删除日志
export function removes(ids) {
    return request({
        url: '/system/log/removes',
        method: "DELETE",
        data: ids
    })
}

// 清空日志
export function cleanLog() {
    return request({
        url: '/system/log/clean',
        method: 'DELETE',
    })
}

